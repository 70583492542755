import request from 'superagent';
// ------------------------------------
// Constants
// ------------------------------------
export const PAGE_LOADING = 'PAGE_LOADING';
export const PAGE_LOADED = 'PAGE_LOADED';

const page404 = {
  title: 'Strona nie istnieje',
  content:
    '<h3>Nie znaleziono strony</h3><p>Sprawdź adres strony lub przejdź do <a href="/">strony głównej</a>.</p>',
  menu: '[]'
};

// ------------------------------------
// Actions
// ------------------------------------
export function loadPage(uri) {
  return dispatch => {
    if (!uri) return;

    dispatch({ type: 'PAGE_LOADING', payload: true });

    request
      .post(window.BofConfig.adaptiveUrl + 'WebServices/cms/Page.asmx/GetPage')
      .send({ uri, site: 0 })
      .set('Accept', 'application/json')
      .end((err, response) => {
        if (err || !response.ok) {
          dispatch({
            type: 'PAGE_LOADED',
            payload: {
              page: page404,
              pageLoading: false
            }
          });
          return;
        }

        let res = response.body.d.records[0];
        if (!res) {
          res = page404;
        }
        dispatch({
          type: 'PAGE_LOADED',
          payload: {
            page: {
              title: res.title,
              content: res.content,
              menu: (res.menu && JSON.parse(res.menu)) || []
            },
            pageLoading: false
          }
        });
      });
  };
}

// ------------------------------------
// Action Handler
// ------------------------------------
const ACTION_HANDLERS = {
  [PAGE_LOADING]: (state, action) => ({
    ...state,
    pageLoading: action.payload,
    page: { menu: [] }
  }),
  [PAGE_LOADED]: (state, action) => ({
    ...state,
    pageLoading: action.payload.pageLoading,
    page: action.payload.page
  })
};

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {
  pageLoading: false,
  page: {
    title: undefined,
    content: undefined,
    menu: []
  }
};

export default function(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
}
