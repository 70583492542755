import 'babel-polyfill';
import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import { browserHistory } from 'react-router';
import routes from './routes';
import App from './containers/App';
import store from './redux/store';
import './config/bof';

ReactDOM.render(
  <App store={store} history={browserHistory} routes={routes} />,
  document.getElementById('root')
);
