import { Link as ReactLink } from 'react-router';
import PropTypes from 'prop-types';
import React from 'react';

export class Link extends React.Component {
  isExternal(uri) {
    return uri.startsWith('http://') || uri.startsWith('https://');
  }

  prepareUri(uri) {
    if (this.isExternal(uri)) {
      return uri;
    } else if (uri.startsWith('/')) {
      return uri;
    }
    return '/' + uri;
  }

  render() {
    const { to, children, ...rest } = this.props;
    return this.isExternal(to) ? (
      <a href={to} {...this.props}>
        {children}
      </a>
    ) : (
      <ReactLink to={this.prepareUri(to)} {...rest}>
        {children}
      </ReactLink>
    );
  }
}

Link.propTypes = {
  to: PropTypes.string.isRequired
};
