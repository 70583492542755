import { combineReducers } from 'redux';
import locationReducer from './location';
import { articleReducer } from 'react-article-module';
import pageReducer from './page';
import navReducer from './nav';

export const makeRootReducer = (asyncReducers) => {
  return combineReducers({
    location: locationReducer,
    articles: articleReducer,
    page: pageReducer,
    nav: navReducer,
    ...asyncReducers
  });
};

export const injectReducer = (store, { key, reducer }) => {
  if (Object.hasOwnProperty.call(store.asyncReducers, key)) return;

  store.asyncReducers[key] = reducer;
  store.replaceReducer(makeRootReducer(store.asyncReducers));
};

export default makeRootReducer;
