import React from 'react';
import { connect } from 'react-redux';
import Nav from '../components/Nav';
import PropTypes from 'prop-types';
import { MapPadding } from 'react-openlayers';
import './MapLayout.scss';
import { loadNav } from '../redux/nav';
import { provideViewSize } from '../hocs/provideViewSize';
import ArticleView from '../views/ArticleView';
import Icon from '../components/Icon';
import { Button } from 'react-bootstrap';
import PopupBox from '../components/PopupBox';
import { ensureArticleModuleConfig } from 'react-article-module';
import translations from '../translations';

const mapDispatchToProps = {
  loadNav
};

const mapStateToProps = state => ({
  nav: state.nav.nav
});

export class MapLayout extends React.Component {
  constructor() {
    super();
    this.state = {
      showTipBox: true,
      tmpHideTipBox: false
    };

    this.onTipBoxVisibilityChange = this.onTipBoxVisibilityChange.bind(this);
  }

  componentDidMount() {
    document.getElementsByClassName(
      'react-openlayers--show-location'
    )[0].style.display = 'inline';

    document.getElementsByClassName('ol-zoom ol-control')[0].style.display =
      'inline';
    document.getElementsByClassName('layer-switch--button-container')[0].style.display =
      'inline';
      document.getElementsByClassName('layer-switch--container')[0].style.display =
      'inline'
    document.querySelector('.tipsbox-btn-globe').style.display = 'inline';

    const tipBoxDeactivated = localStorage.getItem('bof_tipboxdeactivated');
    const tipboxTimestamp = localStorage.getItem('bof_tipboxtimestamp');

    if (tipBoxDeactivated) {
      this.setState({
        showTipBox: false
      });
    }

    if (tipboxTimestamp) {
      const diff = Math.abs(Date.now() - tipBoxDeactivated);
      const diffDays = Math.floor(diff / (1000 * 3600 * 24));
      if (diffDays > 0) {
        this.setState({
          tmpHideTipBox: true
        });
      }
    }

    this.props.loadNav();
  }

  onTipBoxVisibilityChange(neverShowAgain) {
    if (neverShowAgain) {
      localStorage.setItem('bof_tipboxdeactivated', true);
    } else {
      localStorage.removeItem('bof_tipboxdeactivated');
      localStorage.setItem('bof_tipboxtimestamp', Date.now());
    }
    this.setState({ showTipBox: !this.state.showTipBox });
  }

  calcMapPadding() {
    const { viewHeight, viewWidth, location } = this.props;
    if (viewWidth < 769) {
      return [80, 0, viewHeight / 4, 0];
    }


    console.log(location.pathname.indexOf('/hale/'))
    if (location.pathname.indexOf('/hale/') === 0) {
      return [80, viewWidth / 3.5, 0, 0];
    }
    return [80, 420, 0, 0];
  }

  get activeSources() {
    let {
      params: { activeSources },
      sources
    } = this.props;
    activeSources = activeSources ? activeSources.split('+') : [];

    if (!activeSources.length) {
      activeSources = sources
        .filter(s => {
          return s.pressed && s.dataIndex;
        })
        .map(item => item.dataIndex);
    }
    return activeSources;
  }

  render() {
    const { children, nav, location, params } = this.props;
    const { showTipBox, tmpHideTipBox } = this.state;
    const checkBoxChecked = localStorage.getItem('bof_tipboxdeactivated');

    return (
      <div className="map-layout--root">
        <MapPadding padding={this.calcMapPadding()} />
        <Nav nav={nav} />

        <div className="map-layout--components">
          <Button
            className="tipsbox-btn"
            onClick={() =>
              this.setState({ showTipBox: true, tmpHideTipBox: false })
            }
          >
            <Icon name="info" height={22} width={22} />
          </Button>
          {showTipBox &&
            !tmpHideTipBox && (
              <PopupBox
                onClose={this.onTipBoxVisibilityChange}
                closeBtnText={translations[window.BofConfig.globalLang]['Adds']['closeBtnText']}
                checkBoxText={translations[window.BofConfig.globalLang]['Adds']['checkBoxText']}
                checkBoxChecked={!!checkBoxChecked}
              />
            )}
          <div
            className={
              location.pathname.indexOf('/hale/') === 0
                ? 'article-view--hidden'
                : ''
            }
          >
            <ArticleView params={params} activeSources={this.activeSources} />
          </div>
          {children}
        </div>
      </div>
    );
  }
}

MapLayout.propTypes = {
  children: PropTypes.node,
  nav: PropTypes.array,
  loadNav: PropTypes.func,
  viewWidth: PropTypes.number,
  viewHeight: PropTypes.number,
  params: PropTypes.object,
  sources: PropTypes.array.isRequired
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(provideViewSize(ensureArticleModuleConfig(MapLayout)));
