import React from 'react';
import PropTypes from 'prop-types';

const WmsLegends = props => {
  const { urls } = props;
  return (
    <div className="wms-legend-view--container">
      <h4>{'Legenda'}</h4>
      <div className="wms-lengend-view-images">
        {urls.map((url, idx) => (
          <div key={'wms-legend_' + idx}>
            <img src={url} alt="" />
          </div>
        ))}
      </div>
    </div>
  );
};

WmsLegends.propTypes = {
  urls: PropTypes.array
};

export default WmsLegends;
