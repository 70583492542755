import React from 'react';
import Nav from '../components/Nav';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import './CoreLayout.scss';
import { loadNav } from '../redux/nav';

const mapDispatchToProps = {
  loadNav
};

const mapStateToProps = state => ({
  nav: state.nav.nav,
  pageNav: state.page.page.menu
});

export class CoreLayout extends React.Component {
  componentDidMount() {
    this.props.loadNav();
    document.getElementsByClassName('react-openlayers--show-location')[0].style.display = 'none';
    document.getElementsByClassName('ol-zoom ol-control')[0].style.display =
      'none';
    document.getElementsByClassName('layer-switch--button-container')[0].style.display =
      'none';
    document.getElementsByClassName('layer-switch--container')[0].style.display =
      'none';
    document.querySelector('.tipsbox-btn-globe').style.display = 'none';
  }

  render() {
    const { children, nav, pageNav } = this.props;

    return (
      <div className="core-layout--root">
        <Nav nav={nav} pageNav={pageNav} />
        <div className="core-layout--components">{children}</div>
      </div>
    );
  }
}

CoreLayout.propTypes = {
  nav: PropTypes.array,
  pageNav: PropTypes.array,
  loadNav: PropTypes.func,
  children: PropTypes.node
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CoreLayout);
