import React from 'react';
import PropTypes from 'prop-types';
import Icon from '../components/Icon';
import './Checkbox.scss';

const Checkbox = props => {
  const { onCheckChange, checked } = props;
  return (
    <span
      className="checkbox--container"
      onClick={() => onCheckChange(!checked)}
    >
      {checked && <Icon name="checkmark" />}
    </span>
  );
};

Checkbox.propTypes = {
  onCheckChange: PropTypes.func.isRequired,
  checked: PropTypes.bool
};

export default Checkbox;
