import React from 'react';
import PropTypes from 'prop-types';
import Icon from './Icon';
import { Button } from 'react-bootstrap';
import './LegendBtns.scss';

const LegendBtns = props => {
  const { showLegends, onShowLegendsClick } = props;
  return (
    <div className="legendBtns--container">
      <Button
        className={
          !showLegends ? 'showlegends-btn-active' : 'showlegends-btn-inactive'
        }
        onClick={() => onShowLegendsClick(false)}
      >
        <Icon name="profile" height={22} width={22} />
      </Button>
      <Button
        className={
          !showLegends ? 'showlegends-btn-inactive' : 'showlegends-btn-active'
        }
        onClick={() => onShowLegendsClick(true)}
      >
        <Icon name="priceTag" height={22} width={22} />
      </Button>
    </div>
  );
};

LegendBtns.propTypes = {
  showLegends: PropTypes.bool.isRequired,
  onShowLegendsClick: PropTypes.func.isRequired
};

export default LegendBtns;
