export default {
  locale: 'en',

  Search: {
    Search: 'Search',
    Loading: 'Loading',
    Clear: 'Clear search'
  },

  ArticleList: {
    ReadMore: 'Read more',
    Loading: 'Loading...',
    NoResults: 'No results found within range.',
    NoResultsTryChange: 'Try panning or selecting different sources.'
  },

  ArticleWindow: {
    locale: 'en-GB',
    OpenExternal: 'Open source article in new window',
    Close: 'Close',
    Loading: 'Loading article',
    LoadError: 'Could not load article.'
  },

  ArticleComments: {
    locale: 'en-GB',
    Comment: 'Write a comment here',
    DisplayName: 'Display name',
    Email: 'E-mail (not shown)',
    Submit: 'Publish',
    CouldNotPostComment: 'An error occurred.',
    Publishing: 'Publishing',
    Hide: 'Hide'
  },

  Comments: {
    locale: 'en-GB',
    NoTitle: '(No title)',
    Previous: 'Previous',
    Next: 'Next',
    Page: 'Page',
    Of: 'of',
    Flag: 'Flag as inappropriate',
    Flagged: 'Flagged'
  },

  SearchSource: {
    local: 'en-GB',
    showAllResults: 'Show all results',
    goBack: 'Back'
  }
};