import React from 'react';
import { connect } from 'react-redux';
import { browserHistory } from 'react-router';
import { loadPage } from '../redux/page';
import Title from '../components/Title';
import PropTypes from 'prop-types';
import { Link } from '../components/Link';
import { Glyphicon } from 'react-bootstrap';
import './PageView.scss';
import translations from '../translations';

const mapDispatchToProps = {
  loadPage
};

const mapStateToProps = state => ({
  page: state.page.page,
  pageNav: state.page.page.menu,
  pageLoading: state.page.pageLoading
});

function getPath() {
  return window.location.pathname.substr(1) || 'index';
}

export class PageView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      navOpen: false
    };
  }

  componentDidMount() {
    const { params, loadPage } = this.props;
    const path = getPath(params);
    loadPage(path);
    this.setState({ path });
  }

  componentWillReceiveProps(nextProps) {
    const { loadPage } = this.props;
    const newPath = getPath(nextProps.params);
    const existingPath = this.state.path;

    if (newPath !== existingPath) {
      loadPage(newPath);
      this.setState({ path: newPath });
    }
  }

  componentDidUpdate() {
    try {
      window.FlickrEmbedr.detectImages('inline');
      window.FlickrEmbedr.processImages();
    } catch (ex) {
      // eslint-disable-next-line no-console
      console.warn(ex);
    }

    let pageContent = document.getElementsByClassName('bof--pageContent')[0];
    if (pageContent) {
      let links = pageContent.getElementsByTagName('a');
      [].forEach.call(links, link => {
        if (
          link.host !== window.location.host ||
          /\..{3,4}$/.test(link.href) ||
          link.target
        ) {
          return;
        }

        link.onclick = e => {
          e.preventDefault();
          browserHistory.push(link.href);
        };
      });
    }
  }

  render() {
    const {
      page: { title, content },
      pageLoading,
      pageNav,
      params
    } = this.props;
    const { navOpen } = this.state;

    return (
      <div className="bof--content bof--page">
        {pageLoading && (
          <div className="bof--loadingElem">
            <Glyphicon glyph="refresh" className="rotating" />
          </div>
        )}
        <Title title={title} />
        {pageNav &&
          getPath(params) !== 'index' && (
          <div className="bof--page-nav">
            {window.location.pathname !== '/' && (
              <div className="bof--page-nav-back">
                <Link
                  to={
                    window.location.pathname.substring(
                      0,
                      window.location.pathname.lastIndexOf('/')
                    ) || '/'
                  }
                >
                  <Glyphicon glyph="menu-left" />
                  {translations[window.BofConfig.globalLang]['Adds']['goBack']}
                </Link>
              </div>
            )}
            <div className="bof--page-nav-links">
              <input
                type="checkbox"
                className="nav--hamburger"
                checked={navOpen}
                onClick={() => this.setState({ navOpen: !navOpen })}
              />
              <span />
              <div className="nav--items">
                {pageNav.map(pn => (
                  <Link
                    key={pn.uri}
                    to={pn.uri}
                    onClick={() => {
                      this.setState({ navOpen: !navOpen });
                    }}
                  >
                    {pn.title}
                  </Link>
                ))}
              </div>
            </div>
          </div>
        )}
        {translations[window.BofConfig.globalLang]['Adds']['pageFooter2'] && (
            <div 
            className="bof--pageFooter2" 
            dangerouslySetInnerHTML={{ __html: translations[window.BofConfig.globalLang]['Adds']['pageFooter2'] }} />          
          )}
          {translations[window.BofConfig.globalLang]['Adds']['pageFooter3'] && (
            <div 
            className="bof--pageFooter3" 
            dangerouslySetInnerHTML={{ __html: translations[window.BofConfig.globalLang]['Adds']['pageFooter3'] }} />          
          )}
        <div
          className="bof--pageContent"
          dangerouslySetInnerHTML={{ __html: '<div class="page-title">'+ title + '</div>' + content }}
        />
        <footer dangerouslySetInnerHTML={{ __html: translations[window.BofConfig.globalLang]['Adds']['pageFooter'] }} />
        
        <div style={{ clear: 'both' }} />
      </div>
    );
  }
}

PageView.propTypes = {
  params: PropTypes.object.isRequired,
  pageNav: PropTypes.array,
  page: PropTypes.object,
  pageLoading: PropTypes.bool,
  loadPage: PropTypes.func
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PageView);
