import React, { Component } from 'react';
import { Router } from 'react-router';
import PropTypes from 'prop-types';
import { Provider } from 'react-redux';
import { Map, WmsLayer, LayerGroup, LayerSwitch } from 'react-openlayers';
import { TranslatorProvider } from 'react-translate';
import { translations as ArticlModuleTranslations, ArticleModule } from 'react-article-module';
import { addReducerToStore } from '../redux/store';
import translations from '../translations';
import { Button } from 'react-bootstrap';
import Icon from '../components/Icon';
import './App.scss';


class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      center: window.innerWidth > 768 ? [2273242, 6400895] : [2126000, 6396000],
      zoom: 9,
      counter: 0
    };
  }


  getTranslations(lang) {
    return Object.assign(translations[lang], ArticlModuleTranslations[lang]);
  }

  onSearchInit(reducer) {
    addReducerToStore('searchSources', reducer);
  }

  render() {
    const { center, zoom } = this.state;
    const { store, history, routes } = this.props;
    return (

      < Provider store={store} >
        <TranslatorProvider translations={this.getTranslations(window.BofConfig.globalLang)}>
          <Map //projection='EPSG:2180' 
            //projDefs={[
            // {
            //   epsg: 'EPSG:2180',
            //</TranslatorProvider>   def: '+proj=tmerc +lat_0=0 +lon_0=19 +k=0.9993 +x_0=500000 +y_0=-5300000 +ellps=GRS80 +units=m +no_defs'
            //  }
            //]}  
            center={center} zoom={zoom} history={history}
            zoomInTipLabel={translations[window.BofConfig.globalLang]['Adds']['zoomInTipLabel']}
            zoomOutTipLabel={translations[window.BofConfig.globalLang]['Adds']['zoomOutTipLabel']}
            trackTipLabel={translations[window.BofConfig.globalLang]['Adds']['trackTipLabel']}

            //extent={[523600,520600,553000,537400]} 
            minZoom={7} hideScaleLine track>

            {/* <WmsLayer
              uri="https://opencache.statkart.no/gatekeeper/gk/gk.open"
              id="basemap"
              layerName="norges_grunnkart"
              version="1.1.1"
            />
            <WmsLayer
              uri="https://wms.geonorge.no/skwms1/wms.abas"
              id="kommunergrenser"
              layerName="Kommunegrenser"
              version="1.1.1"
            />
            <WmsLayer
              uri="https://wms.geonorge.no/skwms1/wms.abas"
              id="kommunenavn"
              layerName="Kommunenavn"
              version="1.1.1"
              singleTile
            /> */}

            <LayerSwitch tooltip='Mapa podkładowa'>
              <LayerGroup
                id='basemap_group'
                enabledLayers={['OSM-WMS']}
                name='Mapa podkładowa'
                // onLayersChanged={layers => console.log('Layers changed', layers)}
                singleSelect
              // onLayersChanged={showList}
              >
                <WmsLayer
                  baseLayer
                  id='OSM-WMS'
                  key='OSM-WMS'
                  name='Open Street Map (WMS)'
                  uri='https://ows.terrestris.de/osm/service?'
                  layerName='OSM-WMS'
                  //projCode='2180'
                  version='1.1.1'
                  zIndex={-1}
                /> 
                <WmsLayer
                  baseLayer
                  id='Raster'
                  key='Raster'
                  name='Ortofotomapa'
                  uri='https://mapy.geoportal.gov.pl/wss/service/img/guest/ORTO/MapServer/WMSServer'
                  layerName='Raster'
                  //projCode='2180'
                  version='1.3.0'
                  zIndex={-1}
                /> 
                {/*
                <WmsLayer
                  baseLayer
                  id='osmPLmapquest'
                  key='osmPLmapquest'
                  name='Open Street Map'
                  uri='https://osm.mapserv.gridw.pl/service?tiled=true&'
                  layerName='osmPLmapquest'
                  //projCode='2180'
                  version='1.1.1'
                  zIndex={-1}
                />
                <WmsLayer
                  baseLayer
                  id='turystyczna'
                  key='turystyczna'
                  name='Mapa turystyczna'
                  uri='https://mapserv.gridw.pl/karpaty_mapa_turystyczna'
                  layerName='turystyczna'
                  //projCode='2180'
                  version='1.1.1'
                  zIndex={-2}
                /> 
                <WmsLayer
                  baseLayer
                  id='Raster'
                  key='Raster'
                  name='Mapa topograficzna'
                  uri='https://mapy.geoportal.gov.pl/wss/service/img/guest/TOPO/MapServer/WMSServer'
                  layerName='Raster'
                  version='2.0.0'
                  //projCode='2180'
                  zIndex={-3}
                />                  
                <WmsLayer
                  baseLayer
                  key='europakart'
                  name='Europakart'
                  id='europakart'
                  layerName={[
                    'Vmap0Hav',
                    'Vmap0Land',
                    'Land',
                    'Vmap0Kystkontur',
                    'Vmap0AdministrativeGrenser'
                  ].join(',')}
                  version='1.1.1'
                  uri={['https://openwms.statkart.no/skwms1/wms.europa?']}
                  singleTile
                  zIndex={-4}
                /> 
                */}

              </LayerGroup>
            </LayerSwitch>
            <div className="map-globe--components">
              <Button className="tipsbox-btn-globe" title="Przejdź do domyślnego zasięgu" onClick={() => {

                if (this.state.counter === 0) {
                  this.setState({
                    center: window.innerWidth > 768 ? [2208942, 6388596] : [2126000, 6402901],
                    zoom: 9,
                    counter: 1,
                  });
                } else if (this.state.counter === 1) {
                  this.setState({
                    center: window.innerWidth > 768 ? [2208942, 6388595] : [2126000, 6402900],
                    zoom: 9,
                    counter: 0,
                  });
                }
              }
              }>
                <Icon name="globe" height={22} width={22} />
              </Button>
            </div>
            <ArticleModule
              adaptiveUrl={window.BofConfig.adaptiveUrl}
              articleModuleUuid={window.BofConfig.articleModuleUuid}
              onSearchInit={this.onSearchInit}
            //enableSearch={window.BofConfig.enableSearch}
            >
              <Router history={history} children={routes} />
            </ArticleModule>
          </Map>
        </TranslatorProvider>
      </Provider >
    );
  }
}

App.propTypes = {
  store: PropTypes.object.isRequired,
  routes: PropTypes.any.isRequired,
  history: PropTypes.object.isRequired
};

export default App;
